import React from 'react'

const Hero2 = (props) => {
  return (
    <div className="hero_3">
        <h1>{props.heading}</h1>
    </div>
  )
}

export default Hero2